import type { GetCareerCenterResponse } from "@/modules/api/generated-types/jobteaser/context/v1alpha1/context"
import type { CareerCenter } from "@/modules/careerCenter/types"
import type { NextRequest } from "@/modules/fetch/types"
import type { ObjectToCamel } from "@/modules/format/types"
import type { I18nLocale } from "@/modules/i18n/types"

import { getResultFromResponse } from "@/modules/apiRequests/handlers"
import { handleFetchError } from "@/modules/fetch/errorHandler"
import { fetcher } from "@/modules/fetch/fetcher"
import { DEFAULT_STORAGE_DURATION } from "@/modules/serverStorage/constants"

import { CAREER_CENTER_QUERY_KEY } from "./constants"

type GetCareerCenterParams = {
  locale: I18nLocale
  permalink: string
  req?: NextRequest
}

const CAREER_CENTER_ENDPOINT = "/v1alpha1/context/career-center"

export const getCareerCenter = async ({ locale, permalink, req }: GetCareerCenterParams): Promise<CareerCenter> => {
  if (!permalink) {
    throw new Error(`Missing permalink when fetching career center.`)
  }

  return fetcher(`${CAREER_CENTER_ENDPOINT}/${permalink}?locale=${locale}`, {
    nextRequest: req,
    serviceDomain: "KRAKEND_API",
    storageDuration: DEFAULT_STORAGE_DURATION,
    storageKey: `${CAREER_CENTER_QUERY_KEY}::${permalink}::${locale}`,
    storageUniquePerUser: false,
    withUserSession: false,
  })
    .then(getResultFromResponse<ObjectToCamel<GetCareerCenterResponse>>())
    .then(({ careerCenter }) => {
      if (!careerCenter) {
        throw Error(`Unable to retrieve CC for permalink "${permalink}".`)
      }

      if (!careerCenter.careerCenterConf) {
        throw Error(`No careerCenterConf for CC "${permalink}".`)
      }

      if (!careerCenter.careerCenterConf.modules) {
        throw Error(`No careerCenterConf modules for CC "${permalink}".`)
      }

      return careerCenter as CareerCenter
    })
    .catch(handleFetchError(CAREER_CENTER_ENDPOINT))
}
