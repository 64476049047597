import type { PageTranslations } from "@/modules/layouts/contexts/foLayout"
import type { FunctionComponent } from "react"

import { clsx } from "clsx"
import dynamic from "next/dynamic"
import { usePathname } from "next/navigation"

import { hasCustomTheme } from "@/modules/careerCenter/hasCustomTheme"
import { useCareerCenterFetch } from "@/modules/careerCenter/hooks/useCareerCenterFetch"
import { Brand } from "@/modules/headerFooterFO/Brand"
import { FooterLegals } from "@/modules/headerFooterFO/Footer/Legals/FooterLegals"
import { LocalesDropdown } from "@/modules/headerFooterFO/LocalesDropdown"
import { PUBLIC_LOCALES } from "@/modules/locales/constants"
import { useLocale } from "@/modules/locales/useLocale"
import { isChildPage, isFeaturePage } from "@/modules/routing/helpers/isPage"
import { useCurrentUserFetch } from "@/modules/user/client/hooks/useCurrentUserFetch"

import styles from "./Footer.module.css"

type FooterProps = {
  pageTranslations?: PageTranslations
}

const DynamicFooterMenu = dynamic(() =>
  import("@/modules/headerFooterFO/Footer/Menu/FooterMenu").then(mod => mod.FooterMenu)
)

const DynamicSocialsList = dynamic(() =>
  import("@/modules/socials/SocialsList/SocialsList").then(mod => mod.SocialsList)
)

export const Footer: FunctionComponent<FooterProps> = ({ pageTranslations }) => {
  const pathname = usePathname()
  const { dataCurrentUser } = useCurrentUserFetch()
  const { dataCareerCenter } = useCareerCenterFetch()
  const locale = useLocale()

  const hasCustomSchoolTheme = hasCustomTheme(dataCareerCenter?.careerCenterConf.theme)
  const displayLocales =
    !dataCurrentUser?.isLogged && (!isChildPage(pathname) || isFeaturePage(pathname, "corporate", false))
  const withMenu = !hasCustomSchoolTheme && PUBLIC_LOCALES.includes(locale)

  const classnames = clsx({
    [styles.logo__smallScreenBottomMargin]: !displayLocales,
  })

  return (
    <footer className={styles.main} role="contentinfo">
      <div className={styles.container}>
        <div className={styles.brand}>
          <Brand from="footer" JTLogoClassName={classnames} />
          <div className={styles.brandContainer}>
            <div className={styles.locales}>
              <LocalesDropdown from="footer" pageTranslations={pageTranslations} />
            </div>

            {!hasCustomSchoolTheme && <DynamicSocialsList />}
          </div>
        </div>

        {withMenu && <DynamicFooterMenu />}

        <FooterLegals />
      </div>
    </footer>
  )
}
